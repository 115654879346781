import React from "react";
import './Contact.css';
import crest from 'img/contact/crest.jpg';

function Contact() {
  return (
    <div className="contact">
      <div className="container">
        <div className="row align-items-center my-5">
          <div className="col-lg-7">
            <img className="img-fluid rounded mb-4 mb-lg-0"
                 src={crest} alt="New Ross Celtic Crest"
            />
          </div>
          <div className="col-lg-5">
            <h1 className="font-weight-light">New Ross Celtic AFC</h1>
            <p>
              Butlersland<br/>New Ross<br/>Co. Wexford<br/>Y34 YN56
            </p>
          </div>
        </div>
        <div className="contacts-table">
          <div className="contacts__table__row">
            <div className="contacts__cell contacts__cell--shaded">NAME</div>
            <div className="contacts__cell contacts__cell--shaded">ROLE</div>
            <div className="contacts__cell contacts__cell--shaded">PHONE</div>
            <div className="contacts__cell contacts__cell--shaded">EMAIL</div>
          </div>
          <div className="contacts__table__row">
            <div className="contacts__cell">Tommy McGarr</div>
            <div className="contacts__cell">Chairman</div>
            <div className="contacts__cell"><a href="tel:0892476853">089-2476853</a></div>
            <div className="contacts__cell">chairman@newrossceltic.com</div>
          </div>
          <div className="contacts__table__row">
            <div className="contacts__cell">Dick Butler</div>
            <div className="contacts__cell">Treasurer</div>
            <div className="contacts__cell"><a href="tel:0861616543">086-1616543</a></div>
            <div className="contacts__cell">treasurer@newrossceltic.com</div>
          </div>
          <div className="contacts__table__row">
            <div className="contacts__cell">James Kehoe</div>
            <div className="contacts__cell">Secretary</div>
            <div className="contacts__cell"><a href="tel:0873302042">087-3302042</a></div>
            <div className="contacts__cell">secretary@newrossceltic.com</div>
          </div>
          <div className="contacts__table__row">
            <div className="contacts__cell">Tommy Mc Garr</div>
            <div className="contacts__cell">PRO</div>
            <div className="contacts__cell"><a href="tel:0892476853">089-2476853</a></div>
            <div className="contacts__cell">pro@newrossceltic.com</div>
          </div>
          <div className="contacts__table__row">
            <div className="contacts__cell">Patricia Dunphy</div>
            <div className="contacts__cell">Child Welfare Officer</div>
            <div className="contacts__cell"><a href="tel:0872607970">087-2607970</a></div>
            <div className="contacts__cell">secretary@newrossceltic.com</div>
          </div>
          <div className="contacts__table__row">
            <div className="contacts__cell">James Kehoe</div>
            <div className="contacts__cell">ClubMark Officer</div>
            <div className="contacts__cell"><a href="tel:0892476853">089-2476853</a></div>
            <div className="contacts__cell">secretary@newrossceltic.com</div>
          </div>
          <div className="contacts__table__row">
            <div className="contacts__cell">James Kehoe</div>
            <div className="contacts__cell">Membership Coordinator</div>
            <div className="contacts__cell"><a href="tel:0873302042">087-3302042</a></div>
            <div className="contacts__cell">secretary@newrossceltic.com</div>
          </div>
          <div className="contacts__table__row">
            <div className="contacts__cell">Martin O&apos;Sullivan</div>
            <div className="contacts__cell">Website Manager</div>
            <div className="contacts__cell"><a href="tel:0877479597">087-7479597</a></div>
            <div className="contacts__cell">postmaster@newrossceltic.com</div>
          </div>
          <div className="contacts__table__row">
            <div className="contacts__cell">Bryan Mullett</div>
            <div className="contacts__cell">Men&apos;s A Team Manager</div>
            <div className="contacts__cell"><a href="tel:0862390727">086-2390727</a></div>
            <div className="contacts__cell">pro@newrossceltic.com</div>
          </div>
          <div className="contacts__table__row">
            <div className="contacts__cell">Robbie Power</div>
            <div className="contacts__cell">Men&apos;s B Team Manager</div>
            <div className="contacts__cell"><a href="tel:0858344390">085-8344390</a></div>
            <div className="contacts__cell">pro@newrossceltic.com</div>
          </div>
          <div className="contacts__table__row">
            <div className="contacts__cell">Tommy Mc Garr</div>
            <div className="contacts__cell">U19 Men&apos;s Team Manager</div>
            <div className="contacts__cell"><a href="tel:0892476853">089-2476853</a></div>
            <div className="contacts__cell">pro@newrossceltic.com</div>
          </div>
          <div className="contacts__table__row">
            <div className="contacts__cell">Ivan Dunne</div>
            <div className="contacts__cell">Boys U16 Manager</div>
            <div className="contacts__cell"><a href="tel:0857211904">085-7211904</a></div>
            <div className="contacts__cell">pro@newrossceltic.com</div>
          </div>
          <div className="contacts__table__row">
            <div className="contacts__cell">Aaron Davis</div>
            <div className="contacts__cell">Boys U14 Manager</div>
            <div className="contacts__cell"><a href="tel:0873685074">087-3685074</a></div>
            <div className="contacts__cell">pro@newrossceltic.com</div>
          </div>
          <div className="contacts__table__row">
            <div className="contacts__cell">Owen Lennon</div>
            <div className="contacts__cell">Boys U13 Manager</div>
            <div className="contacts__cell"><a href="tel:0876078825">087-6078825</a></div>
            <div className="contacts__cell">pro@newrossceltic.com</div>
          </div>
          <div className="contacts__table__row">
            <div className="contacts__cell">Sean Lennon</div>
            <div className="contacts__cell">Boys U11 Manager</div>
            <div className="contacts__cell"><a href="tel:0876078825">087-6078825</a></div>
            <div className="contacts__cell">pro@newrossceltic.com</div>
          </div>
          <div className="contacts__table__row">
            <div className="contacts__cell">Mark Lawlor</div>
            <div className="contacts__cell">Boys U9 Manager</div>
            <div className="contacts__cell"><a href="tel:0860325166">086-0325166</a></div>
            <div className="contacts__cell">pro@newrossceltic.com</div>
          </div>
          <div className="contacts__table__row">
            <div className="contacts__cell">Karen Jones</div>
            <div className="contacts__cell">Girls U16 Manager</div>
            <div className="contacts__cell"><a href="tel:0872547385">087-2547385</a></div>
            <div className="contacts__cell">pro@newrossceltic.com</div>
          </div>
          <div className="contacts__table__row">
            <div className="contacts__cell">Catherine Barrett</div>
            <div className="contacts__cell">Girls U12 Manager</div>
            <div className="contacts__cell"><a href="tel:0863891257">086-3891257</a></div>
            <div className="contacts__cell">pro@newrossceltic.com</div>
          </div>
          <div className="contacts__table__row">
            <div className="contacts__cell">Tommy Mc Garr</div>
            <div className="contacts__cell">Academy/Mini Celts Contacts</div>
            <div className="contacts__cell"><a href="tel:0892476853">089-2476853</a></div>
            <div className="contacts__cell">pro@newrossceltic.com</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;